import { getReportStu, getCartInfo, addCart } from '@/httpService/services';

export default {
  namespaced: true,
  state: {
    studentList: [], // 参加联考的学生列表
    examInfo: {}, // 联考信息
    activeStuId: null, // 当前选择的学生
    activeSubId: null, // 当前选择的科目
    active: {},
    planShow: false, // 提升计划弹窗
    pdfShow: false, // 导出pdf弹窗
    allpdfShow: false, // 全部题目导出pdf弹窗
    cartDetail: {}, // 购物车信息
    cartQuestions: 0, // 购物车题目数量
    balance: 0 // 账户余额
  },
  getters: {
    examTopItem(state) {
      return {
        ...state.examInfo,
        examStudentList:
          state.studentList?.map(({ id, name, schoolName }) => {
            return {
              id,
              studentName: name,
              schoolName
            };
          }) ?? []
      };
    },
    /* 当前选择的学生 */
    activeStudent(state) {
      return state.studentList.find(i => i.id == state.activeStuId);
    },
    /* 当前选择的科目 */
    activeSubject: (state, getters) => {
      return getters.activeStudent ? getters.activeStudent.subjectList.find(i => i.subjectId == state.active[state.activeStuId]) : {};
    },
    /* 是否显示购买 */
    showBuy(state) {
      return !state.studentList.every(item => item.subjectList.filter(i => i.openBuy).every(i => i.paperIsBuy) );
      // return !state.studentList.every(item => item.subjectList.filter(i => i.openBuy).every(i => i.paperIsBuy) || item.subjectList.every(i => i.price === 0));
    }
  },
  mutations: {
    setStudentList(state, list) {
      state.studentList = list;
    },
    setExamInfo(state, obj) {
      state.examInfo = obj;
    },
    setActiveStuId(state, n) {
      localStorage.setItem('activeStuId', n);
      state.activeStuId = n;
    },
    setActiveSubId(state, n) {
      localStorage.setItem('activeSubId', n);
      state.activeSubId = n;
      state.active[state.activeStuId] = n;
    },
    setActive(state, obj) {
      state.active = obj;
    },
    setPlanShow(state, boolean) {
      state.planShow = boolean;
    },
    setPdfShow(state, boolean) {
      state.pdfShow = boolean;
    },
    setAllPdfShow(state, boolean) {
      state.allpdfShow = boolean;
    },
    setCartDetail(state, obj) {
      state.cartDetail = obj;
    },
    setCartQuestions(state, no) {
      state.cartQuestions = no;
    },
    setBalance(state, balanceNum) {
      state.balance = balanceNum;
    }
  },
  actions: {
    // 获取
    // 获取报告基础信息
    async getReport({ commit }, { examinationId, answerId }) {
      const { report, student } = await getReportStu({
        examinationId,
        answerId
      });
      let studentList = student;
      if (answerId) {
        studentList = student.filter(item => item.answerId == answerId);
      }
      commit('setStudentList', studentList);
      commit('setExamInfo', report);
      let stuId = localStorage.getItem('activeStuId');
      let subId = localStorage.getItem('activeSubId');
      if (!stuId) {
        stuId = studentList[0].id;
      }
      commit('setActiveStuId', Number(stuId));
      if (!subId) {
        subId = studentList[0].subjectList[0].subjectId;
      }
      commit('setActiveSubId', Number(subId));
    },
    // 获取购物车详情
    async getCartDetail({ commit }, { examinationId, answerId }) {
      const res = await getCartInfo({ examinationId, answerId });
      commit('setCartDetail', res);
      let no = 0;
      if (res.cartInfo?.examinationId) {
        const {
          cartInfo: { studentList = [] }
        } = res;
        no = studentList.reduce((total, student) => {
          total += student.subjects.reduce((count, subject) => {
            count += subject.questionIdList.length;
            return count;
          }, 0);
          return total;
        }, 0);
      }
      commit('setCartQuestions', no);
    },
    /**
     * 获取试卷中所有题目
     * @param list 试卷groupList
     * @param {boolean} wrong 是否为错题
     * @return {*}
     */
    async getAllQuestions({ state }, { list, wrong = false, studentId, subjectId }) {
      const studentList = state.cartDetail.cartInfo?.studentList ?? [];
      const cartQuestionIds = studentList.find(item => item.studentId == studentId)?.subjects.find(i => i.subjectId == subjectId)?.questionIdList ?? [];
      const allQuestions = list.reduce((prev, curr) => {
        if (wrong) {
          prev.push(
            ...curr.questionList.filter(item => {
              setCheck(item);
              return item.wrong;
            })
          );
        } else {
          prev.push(
            ...curr.questionList.map(item => {
              setCheck(item);
              return item;
            })
          );
        }
        return prev;
      }, []);
      function setCheck(question) {
        question.checked = cartQuestionIds.includes(question.questionId);
      }
      return allQuestions;
    },
    /**
     * 将原题加入到购物车
     * @param questionId 大题id
     */
    async addToCart({ state, getters }, questionId) {
      await addCart({
        examinationId: state.examInfo.examinationId,
        studentId: getters.activeStudent.id,
        studentName: getters.activeStudent.name,
        subjectId: getters.activeSubject.subjectId,
        subjectName: getters.activeSubject.subjectName,
        questionId,
        classId: getters.activeStudent.classId,
        schoolId: getters.activeStudent.schoolId
      });
    },
    /* 清除数据 */
    clearExam({ commit }) {
      localStorage.removeItem('activeStuId');
      localStorage.removeItem('activeSubId');
      commit('setStudentList', []);
      commit('setExamInfo', {});
      commit('setActive', {});
      commit('setCartDetail', {});
      commit('setCartQuestions', 0);
    }
  }
};
